import React, {useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {LoginContentColumn} from 'components/customer/Auth/helper/layout';
import styled from 'styled-components';
import {CBCButton} from 'components/customer/Auth/StyledElements';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {RegisterResponseInterface} from 'components/customer/Auth/store/accountSlice';

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    & > p > a {
        white-space: nowrap;
    }
    & > h4 {
        margin-bottom: 15px;
    }
    ${({theme}) => {
        if (theme.device.isSmall) {
            return `
            & > h1 {
                font-size: 2rem;
            }
            & > h4 {
                font-size: 1rem;
            }
            & > p {
                font-size: 0.9rem;
            }
            `;
        }
    }}
`;

interface LocationState {
    user: RegisterResponseInterface;
}

export const RegisterSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {user} = location.state as LocationState;
    const userEmail = user?.email || '';
    const mailTo = user?.manufacturer?.email || '';
    const phone = user?.manufacturer?.phone || '';

    useEffect(() => {
        if (!user) {
            // redirect to login if no valid state
            void navigate('/v2/login');
        }
    }, [user]);

    return (
        <Container>
            <Row>
                <LoginContentColumn>
                    <StyledContent>
                        <h4 data-cy="success-header">
                            Your account was successfully created!
                        </h4>
                        <p data-cy="success-message">
                            An email with an activation link was sent to{' '}
                            {userEmail}. If you haven&#39;t received this within
                            the next few minutes, please check your spam folder
                            or contact us via{' '}
                            <a href={`mailto:${mailTo}`}>{mailTo}</a> or{' '}
                            <a href={`tel:${phone}`}>{phone}</a>.
                        </p>
                        <Col style={{marginBottom: '10px'}}>
                            <CBCButton
                                as={Link}
                                to="/v2/login"
                                $width="160px"
                                replace>
                                Go to Login
                            </CBCButton>
                        </Col>
                    </StyledContent>
                </LoginContentColumn>
            </Row>
        </Container>
    );
};
