export const SUSPENDED_OPTIONS = [
    {value: '', label: 'All'},
    {value: '1', label: 'Yes'},
    {value: '0', label: 'No'},
];

export const COUNTRY_OPTIONS = [
    {value: '', label: 'All'},
    {value: '1', label: 'Australia'},
    {value: '2', label: 'New Zealand'},
    {value: '3', label: 'United States'},
];

export enum SIGNUP_SOURCES {
    SIGNUP_ADMIN, // added via admin portal
    SIGNUP_MANUFACTURER, // added via manufacturer portal
    SIGNUP_API, // added through api
    SIGNUP_GENERIC_DOMAIN, // added through loginForm.php with no manufacturer domain
    SIGNUP_MANUFACTURER_DOMAIN, // added through loginForm.php with manufacturer domain
    SIGNUP_REMOTE, // added through loginForm.php and the data was POSTed from some other site
    SIGNUP_SUPPLIER,
    SIGNUP_WEBSITE_GOCABINET,
}

export const SIGNUP_SOURCES_OPTIONS = [
    {value: '', label: 'All'},
    {value: SIGNUP_SOURCES.SIGNUP_ADMIN, label: 'Manually added by admin'},
    {
        value: SIGNUP_SOURCES.SIGNUP_MANUFACTURER,
        label: 'Manually added by manufacturer',
    },
    {
        value: SIGNUP_SOURCES.SIGNUP_API,
        label: "From Manufacturer's register API",
    },
    {
        value: SIGNUP_SOURCES.SIGNUP_REMOTE,
        label: 'From manufacturer website (legacy)',
    },
    {
        value: SIGNUP_SOURCES.SIGNUP_SUPPLIER,
        label: 'Manually added by supplier',
    },
    {
        value: SIGNUP_SOURCES.SIGNUP_MANUFACTURER_DOMAIN,
        label: 'Self-registered on manufacturer domain',
    },
    {
        value: SIGNUP_SOURCES.SIGNUP_GENERIC_DOMAIN,
        label: 'Self-registered on generic domain',
    },
    {
        value: SIGNUP_SOURCES.SIGNUP_WEBSITE_GOCABINET,
        label: 'From gocabinets.com.au',
    },
];
