import {
    Material,
    MaterialEdge,
    ExteriorNonSupply,
    MaterialType,
    Door,
} from 'components/customer/Materials/entity';
import {
    carcaseSet,
    edgeCarcaseSet,
    materialSet,
    edgeMaterialSet,
    doorSet,
} from 'components/customer/Materials/store/materialSlice';
import {Page} from 'store/customer/entity/Page';
import {mapMaterialSetting} from 'components/customer/Materials/helper/materialHelper';
import {
    LegacyDoorInterface,
    mapDoorBack,
} from 'components/customer/Materials/helper/doorHelper';
import {edgeLockSet} from 'components/customer/EdgeFinishes/store/edgeFinishSlice';
import {productUpdate} from 'components/customer/QFPRedux/store/qfpSlice';
import {useAppDispatch} from 'store/customer';
import {useJobContext, useProductContext} from 'contexts';
import {FormikContextType, FormikErrors} from 'formik';
import {PartialRoom} from 'shared/types/PartialRoom';
import {useGetProductDefault} from 'components/customer/Product/helpers/useGetProductDefault';
import {checkProductHasDoorAndDrawerBorders} from 'components/customer/Product/helpers/checkProductHasDoorAndDrawer';
import {isValidNonNegativeNumber as quickCheck} from 'shared/helpers/isValidNonNegativeNumber';
import {
    getBorderWidths,
    PartialFormField,
} from 'components/customer/Materials/helper/getBorderWidths';
import {useGetQFPProductStructureQuery} from 'components/customer/Product/store/productApi';

type setFieldValueType = (
    name: string,
    value: number | string | boolean,
    validate?: boolean
) => void | Promise<void | FormikErrors<any>>;
type setFieldTouchedType = (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
) => void | Promise<void | FormikErrors<any>>;
type setMaterialOptionsType = (data: object) => void;

interface validationDataAction {
    type: string;
    key: string;
    value:
        | {
              id?: number;
              advanced?: boolean;
              isGrained?: number;
              maxHeight?: number;
              maxWidth?: number;
              thickness?: number;
          }
        | LegacyDoorInterface;
}

export type setValidationDataType = (action: validationDataAction) => void;

export enum ActionType {
    ExteriorColour,
    ExteriorEdgeColour,
    CarcaseColour,
    CarcaseEdgeColour,
    Door,
}

const setExteriorValues = (
    extColor: Material,
    page: Page,
    setFieldValue: setFieldValueType,
    setFieldTouched: setFieldTouchedType,
    setValidationData?: setValidationDataType,
    setMaterialOptions?: setMaterialOptionsType,
    setTouched?: boolean
) => {
    if (page == Page.PRODUCT || page == Page.QFP) {
        void setFieldValue('cabinet_ext_brand', extColor.brand.id, false);
        void setFieldValue(
            'cabinet_ext_brand_name',
            extColor.brand.name,
            false
        );
        void setFieldValue('cabinet_ext_finish', extColor.finish, false);
        void setFieldValue('cabinet_ext_substrate', extColor.substrate, false);
        void setFieldValue('material_type_dropdown', extColor.type.id, false);
        void setFieldValue('exterior_colour_name', extColor.name, false);
        void setFieldValue('cabinet_ext_thickness', extColor.thickness, false);
        void setFieldValue('cabinet_ext_image', extColor.image, false);

        setValidationData &&
            setValidationData({
                type: 'single',
                key: 'cabinet_ext_colour',
                value: {
                    isGrained: extColor.is_grained ? 1 : 0,
                    maxHeight: extColor.length,
                    maxWidth: extColor.width,
                    thickness: extColor.thickness,
                    hidden: extColor.is_hidden ? extColor.is_hidden : false,
                },
            });
        setMaterialOptions && setMaterialOptions(mapMaterialSetting(extColor));

        if (page === Page.QFP && setTouched) {
            void setFieldTouched('cabinet_ext_colour', true, false);
            void setFieldTouched('cabinet_ext_brand', true, false);
            void setFieldTouched('cabinet_ext_finish', true, false);
            void setFieldTouched('cabinet_ext_substrate', true, false);
            void setFieldTouched('material_type_dropdown', true, false);
            void setFieldTouched('exterior_colour_name', true, false);
        }

        void setFieldValue('cabinet_ext_colour', extColor.id);
    } else {
        void setFieldValue('exteriorColor', extColor.id);
        void setFieldValue('exteriorBrand', extColor.brand.id);
        void setFieldValue('exteriorBrandMaterial', extColor.brand.id);
        void setFieldValue('exteriorFinish', extColor.finish);
        void setFieldValue('exteriorSubstrate', extColor.substrate);
        void setFieldValue('exteriorType', extColor.type.id);
    }
};

const setExteriorEdgeValues = (
    extEdge: MaterialEdge,
    page: Page,
    setFieldValue: setFieldValueType,
    setFieldTouched: setFieldTouchedType,
    setValidationData?: setValidationDataType,
    setMaterialOptions?: setMaterialOptionsType,
    setTouched?: boolean
) => {
    void setFieldValue(
        'include_drawer_faces',
        extEdge.id != ExteriorNonSupply.EDGE_ID
    );
    void setFieldValue(
        'include_drawer_faces_ext',
        extEdge.id != ExteriorNonSupply.EDGE_ID
    );

    if (page == Page.PRODUCT || page == Page.QFP) {
        void setFieldValue('cabinet_ext_edge_brand', extEdge.brand.id, false);
        void setFieldValue('cabinet_ext_edge_finish', extEdge.finish, false);
        void setFieldValue('cabinet_ext_edge_image', extEdge.image, false);
        void setFieldValue(
            'cabinet_ext_edge_brand_name',
            extEdge.brand.name,
            false
        );
        void setFieldValue('cabinet_ext_edge_colour_name', extEdge.name, false);
        void setFieldValue(
            'cabinet_ext_edge_thickness',
            extEdge.thickness,
            false
        );

        setValidationData &&
            setValidationData({
                type: 'single',
                key: 'cabinet_ext_edge_colour',
                value: {
                    hidden: extEdge.is_hidden ? extEdge.is_hidden : false,
                },
            });
        setMaterialOptions &&
            setMaterialOptions({exterior_edge_color: extEdge.image});

        if (page == Page.QFP && setTouched) {
            void setFieldTouched('cabinet_ext_edge_colour', true, false);
            void setFieldTouched('cabinet_ext_edge_brand', true, false);
            void setFieldTouched('cabinet_ext_edge_finish', true, false);
        }
        void setFieldValue('cabinet_ext_edge_colour', extEdge.id);
    } else {
        void setFieldValue('exteriorEdgeColor', extEdge.id);
        void setFieldValue('exteriorEdgeBrand', extEdge.brand.id);
        void setFieldValue('exteriorEdgeFinish', extEdge.finish);
    }
};

const setCarcaseValues = (
    carcColor: Material,
    page: Page,
    setFieldValue: setFieldValueType,
    setValidationData?: setValidationDataType,
    setMaterialOptions?: setMaterialOptionsType
) => {
    if (page == Page.PRODUCT) {
        void setFieldValue('cabinet_carc_colour', carcColor.id);
        void setFieldValue('cabinet_carc_brand', carcColor.brand.id);
        void setFieldValue('cabinet_carc_brand_name', carcColor.brand.name);
        void setFieldValue('cabinet_carc_finish', carcColor.finish);
        void setFieldValue('cabinet_carc_substrate', carcColor.substrate);
        void setFieldValue('carc_material_type_dropdown', carcColor.type.id);
        void setFieldValue('carcase_colour_name', carcColor.name);
        void setFieldValue('cabinet_carc_thickness', carcColor.thickness);
        void setFieldValue('cabinet_carc_image', carcColor.image);
        setValidationData &&
            setValidationData({
                type: 'single',
                key: 'cabinet_carc_colour',
                value: {
                    isGrained: carcColor.is_grained ? 1 : 0,
                    maxHeight: carcColor.length,
                    maxWidth: carcColor.width,
                    thickness: carcColor.thickness,
                    hidden: carcColor.is_hidden ? carcColor.is_hidden : false,
                },
            });
        setMaterialOptions &&
            setMaterialOptions(
                mapMaterialSetting(carcColor, MaterialType.CARCASE)
            );
    } else {
        void setFieldValue('carcaseColor', carcColor.id);
        void setFieldValue('carcaseBrand', carcColor.brand.id);
        void setFieldValue('carcaseFinish', carcColor.finish);
        void setFieldValue('carcaseSubstrate', carcColor.substrate);
        void setFieldValue('carcaseType', carcColor.type.id);
    }
};

const setCarcaseEdgeValues = (
    carcEdge: MaterialEdge,
    page: Page,
    setFieldValue: setFieldValueType,
    setValidationData?: setValidationDataType,
    setMaterialOptions?: setMaterialOptionsType
) => {
    if (page == Page.PRODUCT) {
        void setFieldValue('cabinet_carc_edge_colour', carcEdge.id);
        void setFieldValue('cabinet_carc_edge_colour_name', carcEdge.name);
        void setFieldValue('cabinet_carc_edge_thickness', carcEdge.thickness);
        void setFieldValue('cabinet_carc_edge_brand', carcEdge.brand.id);
        void setFieldValue('cabinet_carc_edge_brand_name', carcEdge.brand.name);
        void setFieldValue('cabinet_carc_edge_finish', carcEdge.finish);
        void setFieldValue('cabinet_carc_edge_image', carcEdge.image);

        setValidationData &&
            setValidationData({
                type: 'single',
                key: 'cabinet_carc_edge_colour',
                value: {
                    hidden: carcEdge.is_hidden ? extEdge.is_hidden : false,
                },
            });

        setMaterialOptions &&
            setMaterialOptions({carcase_edge_color: carcEdge.image});
    } else {
        void setFieldValue('carcaseEdgeColor', carcEdge.id);
        void setFieldValue('carcaseEdgeBrand', carcEdge.brand.id);
        void setFieldValue('carcaseEdgeFinish', carcEdge.finish);
    }
};

const setDoorValues = (
    door: Door,
    page: Page,
    setFieldValue: setFieldValueType,
    setFieldTouched: setFieldTouchedType,
    room: PartialRoom,
    productFormField: object,
    borders: ReturnType<typeof checkProductHasDoorAndDrawerBorders>,
    wasPreviousDoorAdvanced: boolean,
    setTouched?: boolean,
    setValidationData?: setValidationDataType,
    setMaterialOptions?: setMaterialOptionsType
) => {
    if (page == Page.PRODUCT || page == Page.QFP) {
        void setFieldValue('door_image', door?.image?.name);
        setMaterialOptions &&
            setMaterialOptions({
                cabinet_door: mapDoorBack(door),
            });
        setValidationData &&
            setValidationData({
                type: 'single',
                key: 'cabinet_door',
                value: mapDoorBack(door),
            });

        if (setTouched && page == Page.QFP) {
            void setFieldTouched('cabinet_door', true, false);
        }

        if (door.suffix) {
            void setFieldValue('material_style_dropdown', door.suffix.id);
            if (setTouched && page == Page.QFP) {
                    void setFieldTouched(
                        'material_style_dropdown',
                        true,
                        false
                    );
            }
        }

        // Default face type for advanced door materials is Individual Faces.
        // Uses product context to check if existing material is advanced so
        // face type is not overridden when switching between advanced materials.
        if (!wasPreviousDoorAdvanced && door.is_advanced) {
            void setFieldValue('drawer_face_type', 1); // 1 is individual face
        }

        const props = getBorderWidths(
            door,
            productFormField as PartialFormField,
            room
        );

        // Drawer value set
        if (quickCheck(props.drawer_border_width_top)) {
            void setFieldValue(
                'drawer_border_width_top',
                props.drawer_border_width_top,
                false
            );
            setTouched &&
                void setFieldTouched('drawer_border_width_top', true, false);
        }

        if (quickCheck(props.drawer_border_width_left)) {
            void setFieldValue(
                'drawer_border_width_left',
                props.drawer_border_width_left,
                false
            );
            setTouched &&
                void setFieldTouched('drawer_border_width_left', true, false);
        }

        if (quickCheck(props.drawer_border_width_bottom)) {
            void setFieldValue(
                'drawer_border_width_bottom',
                props.drawer_border_width_bottom,
                false
            );
            setTouched &&
                void setFieldTouched('drawer_border_width_bottom', true, false);
        }

        if (quickCheck(props.drawer_border_width_right)) {
            void setFieldValue(
                'drawer_border_width_right',
                props.drawer_border_width_right,
                false
            );
            setTouched &&
                void setFieldTouched('drawer_border_width_right', true, false);
        }

        if (quickCheck(props.drawer_hori_height)) {
            void setFieldValue(
                'drawer_hori_height',
                props.drawer_hori_height,
                false
            );
            setTouched &&
                void setFieldTouched('drawer_hori_height', true, false);
        }
        // Drawer value set

        // Door value set
        if (quickCheck(props.border_width_top)) {
            void setFieldValue(
                'border_width_top',
                props.border_width_top,
                false
            );
            setTouched && void setFieldTouched('border_width_top', true, false);
        }

        if (quickCheck(props.border_width_left)) {
            void setFieldValue(
                'border_width_left',
                props.border_width_left,
                false
            );
            setTouched &&
                void setFieldTouched('border_width_left', true, false);
        }

        if (quickCheck(props.border_width_bottom)) {
            void setFieldValue(
                'border_width_bottom',
                props.border_width_bottom,
                false
            );
            setTouched &&
                void setFieldTouched('border_width_bottom', true, false);
        }

        if (quickCheck(props.border_width_right)) {
            void setFieldValue(
                'border_width_right',
                props.border_width_right,
                false
            );
            setTouched &&
                void setFieldTouched('border_width_right', true, false);
        }

        if (quickCheck(props.vert_width)) {
            void setFieldValue('vert_width', props.vert_width, false);
            setTouched && void setFieldTouched('vert_width', true, false);
        }

        if (quickCheck(props.hori_height)) {
            void setFieldValue('hori_height', props.hori_height, false);
            setTouched && void setFieldTouched('hori_height', true, false);
        }
        // Door value set

        if (door.default_edge_finish_bottom >= 0) {
            void setFieldValue(
                'panel_edge_bottom',
                door.default_edge_finish_bottom,
                false
            );
            props.panel_edge_bottom = door.default_edge_finish_bottom;
            setTouched &&
                void setFieldTouched('panel_edge_bottom', true, false);
        }
        if (door.default_edge_finish_join >= 0) {
            void setFieldValue(
                'panel_edge_join',
                door.default_edge_finish_join,
                false
            );
            props.panel_edge_join = door.default_edge_finish_join;
            setTouched && void setFieldTouched('panel_edge_join', true, false);
        }
        if (door.default_edge_finish_left >= 0) {
            void setFieldValue(
                'panel_edge_left',
                door.default_edge_finish_left,
                false
            );
            props.panel_edge_left = door.default_edge_finish_left;
            setTouched && void setFieldTouched('panel_edge_left', true, false);
        }
        if (door.default_edge_finish_right >= 0) {
            void setFieldValue(
                'panel_edge_right',
                door.default_edge_finish_right,
                false
            );
            props.panel_edge_right = door.default_edge_finish_right;
            setTouched && void setFieldTouched('panel_edge_right', true, false);
        }
        if (door.default_edge_finish_top >= 0) {
            void setFieldValue(
                'panel_edge_top',
                door.default_edge_finish_top,
                false
            );
            props.panel_edge_top = door.default_edge_finish_top;
            setTouched && void setFieldTouched('panel_edge_top', true, false);
        }

        void setFieldValue('cabinet_door', door.id);
        void setFieldValue('cabinet_door_name', door.name);
        return props;
    } else {
        if (door.default_border_width_top != null) {
            void setFieldValue(
                'doorBorderWidthTop',
                door.default_border_width_top
            );
        }
        if (door.default_border_width_left != null) {
            void setFieldValue(
                'doorBorderWidthLeft',
                door.default_border_width_left
            );
        }
        if (door.default_border_width_bottom != null) {
            void setFieldValue(
                'doorBorderWidthBottom',
                door.default_border_width_bottom
            );
        }
        if (door.default_border_width_right != null) {
            void setFieldValue(
                'doorBorderWidthRight',
                door.default_border_width_right
            );
        }
        if (door.default_rails_horizontal_height != null) {
            void setFieldValue(
                'doorAdvancedRailHeight',
                door.default_rails_horizontal_height
            );
        }
        if (door.default_rails_vertical_width != null) {
            void setFieldValue(
                'doorAdvancedRailWidth',
                door.default_rails_vertical_width
            );
        }

        void setFieldValue('exteriorStyle', door.id);
        void setFieldValue('isAdvancedDoor', door.is_advanced);
        if (door.suffix) {
            void setFieldValue('exteriorCategory', door.suffix.id);
        }
    }
};

export interface DispatchMaterialInterface {
    page?: Page;
    index?: number;
    formikContext?: FormikContextType<any>;
}

export const useDispatchMaterial = ({
    page = Page.PRODUCT,
    index = 0,
    formikContext,
}: DispatchMaterialInterface) => {
    const dispatch = useAppDispatch();
    const {setValidationData, setMaterialOptions} = useProductContext<{
        productDataStore: object;
        getMaterialOptions: () => object;
        setValidationData: setValidationDataType;
        setMaterialOptions: setMaterialOptionsType;
    }>(page);

    const {room} = useJobContext() as {room: PartialRoom};
    const values = formikContext ? formikContext.values : {};

    const {data: productForm} = useGetQFPProductStructureQuery(
        {cabinetType: Number(values.cabinet_type)},
        {skip: typeof values.cabinet_type === 'undefined'}
    );
    const {data: defaultsValues} = useGetProductDefault(values && values.cabinet_type);

    return <T extends Material | MaterialEdge | Door>(
        type: ActionType,
        payload: T,
        updateValue = true,
        updateValueOnly = false,
        setTouched = true,
        manualOverride = false,
        previousDoorAdvanced = false
    ) => {
        let setFieldValue;
        let setFieldTouched;
        if (formikContext) {
            setFieldTouched = formikContext.setFieldTouched;
            setFieldValue = formikContext.setFieldValue;
        }

        switch (type) {
            case ActionType.ExteriorColour:
                const extColor = payload as Material;
                if (!updateValueOnly) dispatch(materialSet(extColor, index));

                if (updateValue) {
                    setExteriorValues(
                        extColor,
                        page,
                        setFieldValue,
                        setFieldTouched,
                        setValidationData,
                        setMaterialOptions,
                        setTouched
                    );
                }
                break;

            case ActionType.ExteriorEdgeColour:
                const extEdge = payload as MaterialEdge;
                if (!updateValueOnly) dispatch(edgeMaterialSet(extEdge, index));

                if (updateValue) {
                    setExteriorEdgeValues(
                        extEdge,
                        page,
                        setFieldValue,
                        setFieldTouched,
                        setValidationData,
                        setMaterialOptions,
                        setTouched
                    );
                }
                break;

            case ActionType.CarcaseColour:
                const carcColor = payload as Material;
                if (!updateValueOnly) dispatch(carcaseSet(carcColor, index));

                if (updateValue) {
                    setCarcaseValues(
                        carcColor,
                        page,
                        setFieldValue,
                        setValidationData,
                        setMaterialOptions
                    );
                }
                break;

            case ActionType.CarcaseEdgeColour:
                const carcEdge = payload as MaterialEdge;
                if (!updateValueOnly) dispatch(edgeCarcaseSet(carcEdge, index));

                if (updateValue) {
                    setCarcaseEdgeValues(
                        carcEdge,
                        page,
                        setFieldValue,
                        setValidationData,
                        setMaterialOptions
                    );
                }
                break;

            case ActionType.Door:
                const door = payload as Door;

                if (!updateValueOnly) {
                    dispatch(doorSet(door, index));

                    if (door.is_locked_edge_finish) {
                        dispatch(edgeLockSet({locked: true, index}));
                    } else {
                        dispatch(edgeLockSet({locked: false, index}));
                    }
                }

                if (updateValue) {
                    const borders = checkProductHasDoorAndDrawerBorders(
                        productForm ? productForm.originalStructure : []
                    );

                    const props = setDoorValues(
                        door,
                        page,
                        setFieldValue,
                        setFieldTouched,
                        room,
                        defaultsValues
                            ? defaultsValues.cabinet_form_fields
                            : {},
                        borders,
                        previousDoorAdvanced,
                        setTouched,
                        setValidationData,
                        setMaterialOptions
                    );

                    previousDoorAdvanced = door.is_advanced;

                    if (props && page == Page.QFP && manualOverride) {
                        dispatch(productUpdate(props));
                    }
                }
                break;
        }
    };
};
