import {Field} from 'formik';
import React from 'react';
import {
    FormControl,
    FieldContainer,
} from 'components/customer/Auth/StyledElements';

const InputField = ({
    type,
    name,
    placeholder,
    label,
    disabled,
    required,
    maxLength,
}: {
    type: string;
    name: string;
    placeholder: string;
    label: string | JSX.Element;
    disabled?: boolean;
    required?: boolean;
    maxLength?: number;
}) => {
    return (
        <FieldContainer $required={required}>
            <label>{label}</label>
            <div className="input-group">
                <FormControl
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    as={Field}
                    className="form-control"
                    disabled={disabled}
                    aria-label={name}
                    maxLength={maxLength}
                />
            </div>
        </FieldContainer>
    );
};

export default InputField;
