import React, {useCallback, useMemo} from 'react';
import {
    FieldContainer,
    StyledDropdown,
} from 'components/customer/Auth/StyledElements';
import {Dropdown} from 'shared';
import {CountryInterface} from 'components/customer/Auth/store/accountSlice';
import {useFormikContext} from 'formik';
import {RegisterFormFields} from 'components/customer/Auth/Registration/helpers';

interface CountryDropdownProps {
    countries: CountryInterface[];
    selectCallback?: (country: string) => void;
}

const CountryDropdown = ({countries, selectCallback}: CountryDropdownProps) => {
    const {setValues, values} = useFormikContext<Partial<RegisterFormFields>>();

    const onCountrySelection = useCallback(
        (_name: string, country: string) => {
            void setValues({
                ...values,
                country,
                address: '',
                address2: '',
                suburb: '',
                postcode: '',
                state: '',
                addressSearch: '',
            });
            if (selectCallback) {
                selectCallback(country);
            }
        },
        [values, countries]
    );

    const countriesOptions = useMemo(() => {
        const options = [
            {
                id: '',
                name: 'Select country',
                value: '',
            },
        ];
        if (countries) {
            options.push(
                ...countries.map((country) => ({
                    id: country.id,
                    name: country.name,
                    value: country.id,
                }))
            );
        }
        return options;
    }, [countries]);

    return (
        <FieldContainer $required>
            <label>Country</label>
            <StyledDropdown>
                <Dropdown
                    options={countriesOptions}
                    name="country"
                    data-testid="country-select"
                    value={values.country}
                    selectHandler={onCountrySelection}
                />
            </StyledDropdown>
        </FieldContainer>
    );
};

export default CountryDropdown;
