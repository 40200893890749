import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import {useNotificationContext} from 'contexts';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    Loader,
    currentPageSet,
    edgeSet,
    loadingSet,
    materialsAdd,
    materialsSet,
    selectBrandsEdgeIds,
    selectCabinetType,
    selectCurrentPage,
    selectDoor,
    selectFinishEdgeIds,
    totalPagesSet,
    totalRecordsSet,
    selectMaterialType,
    selectMaterial,
    selectMenu,
    selectEdge,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {shallowEqual} from 'react-redux';
import {useSearchText} from 'components/customer/AdvancedMaterials/helpers/useSearchText';
import {useCallback, useEffect, useRef} from 'react';
import {Door} from 'components/customer/Materials/entity';
import {
    MaterialSearchRequest,
    useLazyListMaterialEdgesQuery,
} from 'components/customer/Materials/store/materialApi';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';
import {genericMessageHandler} from 'shared/helpers';
import {mapEdgeMaterial} from 'components/customer/AdvancedMaterials/helpers/mappers';
import {Material} from 'components/customer/AdvancedMaterials/entity/Material';
import OGMarerial from 'components/customer/Materials/entity/Material';

export const useFetchEdges = () => {
    const dispatch = useAppDispatch();
    const {notify} = useNotificationContext();

    const [fetchEdges] = useLazyListMaterialEdgesQuery();

    const initialized = useRef(false);

    const triggerQueryAndSetState = useCallback(
        async (params: MaterialSearchRequest, setEdge = false) => {
            const currentPage = params.currentPage || 1;
            try {
                let loading = Loader.LOADING_INITIAL;
                if (initialized.current) {
                    if (currentPage == 1) {
                        loading = Loader.LOADING_DATA;
                    } else {
                        loading = Loader.LOADING_PAGINATION;
                    }
                }

                dispatch(loadingSet(loading));
                const {
                    data: {data: edges, pagination, groupCount},
                } = await fetchEdges(params, true);

                if (!setEdge) {
                    if (edges && edges.length > 0) {
                        if (currentPage == 1) {
                            dispatch(totalPagesSet(pagination.page_count));
                            dispatch(
                                totalRecordsSet(groupCount || edges.length)
                            );
                            dispatch(materialsSet(edges.map(mapEdgeMaterial)));
                        } else {
                            dispatch(materialsAdd(edges.map(mapEdgeMaterial)));
                        }
                    } else {
                        dispatch(totalPagesSet(0));
                        dispatch(totalRecordsSet(groupCount || edges.length));
                        dispatch(currentPageSet(1));
                        dispatch(materialsSet([]));
                    }
                } else {
                    if (edges && edges.length > 0) {
                        const edge = edges[0];
                        dispatch(edgeSet(mapEdgeMaterial(edge)));
                    }
                }
            } catch (error) {
                genericMessageHandler(notify, {
                    message: 'Error while fetching materials',
                });
            } finally {
                dispatch(loadingSet(Loader.IDLE));
                initialized.current = true;
            }
        },
        []
    );

    return {
        triggerQueryAndSetState,
    };
};

export const useEdges = (hasDoor: boolean) => {
    const {data: user} = useGetUserQuery();
    const cabinetType = useAppSelector(selectCabinetType);
    const currentPage = useAppSelector(selectCurrentPage);

    const materialType = useAppSelector(selectMaterialType, shallowEqual);
    const door = useAppSelector(selectDoor, shallowEqual);
    const edge = useAppSelector(selectEdge, shallowEqual);
    const selectedFinishes = useAppSelector(selectFinishEdgeIds, shallowEqual);
    const selectedBrands = useAppSelector(selectBrandsEdgeIds, shallowEqual);
    const selectedMaterial = useAppSelector(
        selectMaterial,
        shallowEqual
    ) as Material<OGMarerial>;
    const selectedMenu = useAppSelector(selectMenu);

    const {triggerQueryAndSetState} = useFetchEdges();

    const {searchText} = useSearchText();

    const listEdges = useCallback(
        (
            door: Door,
            finishes: string[],
            brands: string[],
            currentPage: number,
            searchText: string
        ) => {
            void triggerQueryAndSetState({
                id:
                    hasDoor &&
                    door &&
                    door?.is_default_edge_type_locked &&
                    door?.edge &&
                    edge?.id
                        ? edge.id
                        : undefined,
                cabinetType,
                manufacturerId: user?.manufacturerId,
                doorFilter: selectedMaterial.data.door_filter,
                brands,
                finishes,
                keywords: searchText,
                currentPage,
                pageSize: 50,
                materialType: materialType.id,
            });
        },
        [hasDoor, user, cabinetType, materialType, selectedMaterial]
    );

    useEffect(() => {
        if (selectedMenu != Menu.EDGE_MATERIAL) return;

        listEdges(
            door,
            selectedFinishes,
            selectedBrands,
            currentPage,
            searchText
        );
    }, [
        door,
        selectedBrands,
        selectedFinishes,
        selectedMenu,
        searchText,
        currentPage,
    ]);
};
