import {Bench} from 'components/customer/BTM/entity/Bench';
import {IBenchOption} from 'components/customer/BTM/entity/IBenchOption';
import {BenchOption} from 'components/customer/BTM/entity/BenchOption';
import {getShape, Shape} from 'components/customer/BTM/entity/Shape';
import {
    mapButtJoin,
    mapFormFactors,
    mapMaterialDimension,
} from 'components/customer/BTM/helper/materialMappers';

export const mapMaterialFormFactor = (bench: Bench) => {
    bench.material = bench?.material ? mapFormFactors(bench.material) : null;

    return bench;
};

export const mapMaterialChange = (bench: Bench) => {
    bench.material = mapMaterialDimension(bench.material);

    return bench;
};

export const mapOptionBack = (bench: Bench) => {
    bench.options = bench.options.map(
        (option) =>
            ({
                ...option,
                value: JSON.parse(String(option.value)) as IBenchOption,
            } as BenchOption<IBenchOption>)
    );

    return bench;
};

export const mapShape = (bench: Bench) => {
    if (bench.shape) {
        bench.shape.type = getShape(bench.shape.identifier);
    }

    return bench;
};

interface DimensionOption extends IBenchOption {
    length: number;
}
export const mapDimension = (bench: Bench) => {
    const dimensions = bench.options.find(
        (option) => option.name == 'dimensions'
    );

    if (dimensions) {
        const sides = dimensions.value.map(
            (dimension: DimensionOption) => dimension.length
        );
        bench.dimension = mapDimensionFromSides(sides, bench?.shape?.type);
    }

    return bench;
};

export const mapDimensionFromSides = (sides: number[], shape: string) => {
    let dimensions = '';
    if (shape == Shape.SQR) {
        dimensions = `${sides[0]} x ${sides[1]}`;
    } else if (shape == Shape.ANG) {
        const [width, , , , , height] = sides;
        dimensions = `${height} x ${width}`;
    } else if (shape == Shape.USHAPE) {
        const [width, rHeight, , , , , , lHeight] = sides;
        dimensions = `${lHeight} x ${width} x ${rHeight}`;
    }

    return dimensions;
};

export const mapMaterialButtJoin = (bench: Bench) => {
    bench.material = mapButtJoin(bench.material);
    return bench;
};
