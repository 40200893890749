import React from 'react';
import {StyledSuggestion} from '../StyledElements';
import {Spinner} from 'react-bootstrap';
import Icon from 'shared/Icon';

interface SuggestionListProps {
    suggestions: Array<{
        id: string;
        name: string;
        cantFindAddress: boolean;
        onClick: () => void;
    }>;
    isLoading: boolean;
}

const SuggestionList = ({suggestions, isLoading}: SuggestionListProps) => {
    return (
        <StyledSuggestion>
            <ul>
                {suggestions && suggestions.length > 0 ? (
                    isLoading ? (
                        <Spinner size="sm" animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : (
                        suggestions.map((item) => (
                            <li
                                data-cy={`suggestion-${item.id}`}
                                className={
                                    item.cantFindAddress
                                        ? 'cantFindAddress'
                                        : ''
                                }
                                key={item.id}
                                onClick={item.onClick}>
                                {!item.cantFindAddress ? (
                                    <Icon
                                        key="icon-pin"
                                        width="24px"
                                        iconName={'Location-Pin-Drop.svg'}
                                        style={{marginRight: 5}}
                                    />
                                ) : null}
                                {item.name}
                            </li>
                        ))
                    )
                ) : null}
            </ul>
        </StyledSuggestion>
    );
};

export default SuggestionList;
