import React, {useCallback, useMemo, useState} from 'react';
import SuggestionList from 'components/customer/Auth/Registration/SuggestionList';
import Icon from 'shared/Icon';
import {
    FieldContainer,
    FormControl,
} from 'components/customer/Auth/StyledElements';
import {useFormikContext} from 'formik';
import {useDebouncedCallback} from 'use-debounce';
import {useGetPredictions} from 'components/customer/Settings/helpers/useGetPredictions';
import {PredictionInterface} from 'components/customer/Settings/store/settingsApi';
import {useGetPredictionDetails} from 'components/customer/Settings/helpers/useGetPredictionDetails';

interface SearchAddressFieldProps {
    disabled: boolean;
    countryId: string;
    addressStates: {
        id: string;
        name: string;
    }[];
    cantFindAddress: boolean;
    setCantFindAddress: React.Dispatch<React.SetStateAction<boolean>>;
}

interface GenericValues {
    [key: string]: string;
}

const SearchAddressField = ({
    disabled,
    countryId,
    addressStates,
    cantFindAddress,
    setCantFindAddress,
}: SearchAddressFieldProps) => {
    const {setFieldValue, setValues, values} =
        useFormikContext<Partial<GenericValues>>();
    const [suggestionDisplayed, setSuggestionDisplayed] =
        useState<boolean>(false);
    const [result, setResult] = useState<Array<PredictionInterface>>(null);

    const {getPredictions, isLoading: isLoadingPredictions} =
        useGetPredictions();

    const {getPredictionDetails} = useGetPredictionDetails();

    const fetchPredictions = useDebouncedCallback(
        async (input: string, selectedCountry: string) => {
            const response = await getPredictions(input, selectedCountry);
            setResult(response);
        },
        300
    );

    const onAddressChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = event.target.value;
            if (!!inputValue && !suggestionDisplayed) {
                setSuggestionDisplayed(true);
            }
            void setFieldValue('addressSearch', inputValue);
            // Fetch autocomplete predictions from Google Places API
            void fetchPredictions(inputValue, countryId);
        },
        [suggestionDisplayed, countryId]
    );

    const handleAddressSelection = async (selected: PredictionInterface) => {
        const data = await getPredictionDetails(selected.id);
        const addressState = addressStates.find(
            (state) => state.name === data.state
        );

        const addressBuilder = [];

        if (data) {
            if (data.streetName || data.streetNumber) {
                addressBuilder.push(
                    [data.streetNumber, data.streetName]
                        .filter(Boolean)
                        .join(' ')
                );
            }

            if (data.city) {
                addressBuilder.push(data.city);
            }

            addressBuilder.push(data.suburb);

            if (!!addressState) {
                addressBuilder.push(addressState.name);
            }

            addressBuilder.push(data.postcode);
        }

        void setValues({
            ...values,
            address: [data.streetNumber, data.streetName]
                .filter(Boolean)
                .join(' '),
            address2: '',
            suburb: [data.city, data.suburb].filter(Boolean).join(', '),
            postcode: data.postcode,
            state: addressState?.id || '',
            addressSearch: addressBuilder.join(', '),
        });
        // clear results
        setResult(null);
        setSuggestionDisplayed(false);
        setCantFindAddress(false);
    };

    const suggestList = useMemo(() => {
        return [
            ...(result
                ? result.map((item) => ({
                      id: item.id,
                      name: item.name,
                      cantFindAddress: false,
                      onClick: () => {
                          void handleAddressSelection(item);
                      },
                  }))
                : []),
            {
                id: '0',
                name: `Can't find the address? Add it manually`,
                cantFindAddress: true,
                onClick: () => {
                    setResult(null);
                    setSuggestionDisplayed(false);
                    setCantFindAddress(true);
                    void setValues({
                        ...values,
                        address: '',
                        suburb: '',
                        postcode: '',
                        state: '',
                        addressSearch: '',
                    });
                },
            },
        ];
    }, [values, result]);

    return (
        <FieldContainer $required={!cantFindAddress}>
            <label>Address</label>
            <div className="input-group has-validation">
                <FormControl
                    type="text"
                    name="addressSearch"
                    aria-label="addressSearch"
                    placeholder="Search for address.."
                    onChange={onAddressChange}
                    className="form-control"
                    value={values.addressSearch}
                    title={values.addressSearch}
                    disabled={disabled}
                    autoComplete="off"
                    style={{paddingRight: 35}}
                />
                {suggestionDisplayed && values.addressSearch !== '' ? (
                    <SuggestionList
                        suggestions={suggestList}
                        isLoading={isLoadingPredictions}
                    />
                ) : null}
                <Icon
                    key="icon-search"
                    width="28px"
                    iconName={'search-grey.svg'}
                />
            </div>
        </FieldContainer>
    );
};

export default SearchAddressField;
