import React, {useCallback} from 'react';
import {ADDRESS_FIELDS} from 'components/customer/Auth/Registration/helpers';
import InputField from 'components/customer/Auth/Registration/InputField';
import {
    FieldContainer,
    StyledDropdown,
} from 'components/customer/Auth/StyledElements';
import {Dropdown} from 'shared';
import {CountryInterface} from 'components/customer/Auth/store/accountSlice';
import {useFormikContext} from 'formik';

interface CannotFindAddressProps {
    postalCodeLength: number;
    isLoadingCountry: boolean;
    selectedCountry: CountryInterface;
}

const CannotFindAddress = ({
    postalCodeLength = 5,
    isLoadingCountry,
    selectedCountry,
}: CannotFindAddressProps) => {
    const {setFieldValue, values} = useFormikContext<{state: string}>();
    const onStateChange = useCallback((name: string, value: string) => {
        void setFieldValue(name, value);
    }, []);
    return (
        <>
            {ADDRESS_FIELDS.map((field) => {
                if (field.name === 'postcode') {
                    field.disabled = isLoadingCountry;
                    field.maxLength = postalCodeLength;
                }
                return (
                    <InputField
                        key={field.name}
                        type={field.type}
                        label={field.label}
                        name={field.name}
                        placeholder={field.placeholder}
                        required={field.required}
                        disabled={field?.disabled}
                        maxLength={field?.maxLength}
                    />
                );
            })}
            {selectedCountry?.states ? (
                <FieldContainer $required>
                    <label>{selectedCountry?.stateLabel}</label>
                    <StyledDropdown>
                        <Dropdown
                            options={[
                                {
                                    id: '',
                                    name: `Select ${selectedCountry?.stateLabel}`,
                                    value: '',
                                },
                                ...selectedCountry?.states,
                            ]}
                            name="state"
                            value={values.state}
                            selectHandler={onStateChange}
                        />
                    </StyledDropdown>
                </FieldContainer>
            ) : null}
        </>
    );
};

export default CannotFindAddress;
