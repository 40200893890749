import {Formik, Form} from 'formik';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Alert, Col, Container, Row, Spinner} from 'react-bootstrap';
import {
    CBCButton,
    Title,
    InputWrapper,
} from 'components/customer/Auth/StyledElements';
import {Link, useNavigate} from 'react-router-dom';
import {DisplayMessage} from 'shared/helpers/AuthHelpers';
import {useAppSelector} from 'store/customer';
import {
    selectManufacturerCountryId,
    selectManufacturerId,
    selectPermitCustomerSelfRegistration,
} from 'store/customer/brandingSlice';
import {LoginContentColumn} from 'components/customer/Auth/helper/layout';
import {
    CountryInterface,
    useGetCountriesQuery,
    useLazyGetCountryQuery,
    useRegisterMutation,
} from 'components/customer/Auth/store/accountSlice';
import InputField from 'components/customer/Auth/Registration/InputField';
import {Response} from 'service';
import styled from 'styled-components';
import Icon from 'shared/Icon';
import {Flex} from 'shared/components/StyledComponents';
import SearchAddressField from 'components/customer/Auth/Registration/SearchAddressField';
import {
    BUSINESS_FIELDS,
    getFormSchema,
    PERSONAL_INFO_FIELDS,
    RegisterFormFields,
    REGISTRATION_DEFAULTS,
} from 'components/customer/Auth/Registration/helpers';
import CannotFindAddress from 'components/customer/Auth/Registration/CannotFindAddress';
import CountryDropdown from 'components/customer/Auth/Registration/CountryDropdown';
import {useGetCustomerDepotsQuery} from 'store/customer/depotApi';
import DepotDropdown from 'components/customer/Auth/Registration/DepotDropdown';

export const RegisterForm = (): JSX.Element => {
    const permitSelfRegistration = useAppSelector(
        selectPermitCustomerSelfRegistration
    );
    const countryId = useAppSelector(selectManufacturerCountryId);
    const manufacturerId = useAppSelector(selectManufacturerId);

    const {
        data: countries,
        isLoading: isLoadingCountries,
        isError: countriesError,
    } = useGetCountriesQuery();
    const [
        getCountry,
        {
            data: countryData,
            isLoading: loadingCountry,
            isFetching: fetchingCountry,
        },
    ] = useLazyGetCountryQuery();
    const {data: customerDepots} = useGetCustomerDepotsQuery();
    const [register, {isLoading: isSubmitting}] = useRegisterMutation();
    const navigate = useNavigate();

    const [error, setError] = useState<string>(null);
    const [cantFindAddress, setCantFindAddress] = useState(false);
    const [selectedCountry, setSelectedCountry] =
        useState<CountryInterface>(null);
    const [companyExpanded, setCompanyExpanded] = useState<boolean>(false);

    const formSchema = useMemo(() => {
        return getFormSchema(
            selectedCountry?.stateLabel.toLowerCase(),
            !countryId,
            !!selectedCountry,
            cantFindAddress
        );
    }, [selectedCountry, countryId, cantFindAddress]);

    useEffect(() => {
        if (countryId && countries) {
            setSelectedCountry(
                countries?.find(({id}) => Number(id) == countryId)
            );
        }
    }, [countries, countryId]);

    useEffect(() => {
        if (selectedCountry) {
            void getCountry({countryId: selectedCountry.id});
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (countriesError) {
            setError('Unable to load country list.');
        }
    }, [countriesError]);

    useEffect(() => {
        if (companyExpanded) {
            window.scrollTo({
                left: 0,
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [companyExpanded]);

    const onSubmit = useCallback(
        (values: RegisterFormFields) => {
            // handle submit
            const addressState = selectedCountry?.states.find(
                (state) => state.id === values.state
            );
            void register({
                name: values.fullName,
                email: values.email,
                phone: values.phone,
                customer_address: values.address,
                customer_address2: values.address2,
                customer_suburb: values.suburb,
                customer_country_state: addressState.id,
                customer_postcode: values.postcode,
                country: selectedCountry.id,
                abn: values.abn,
                acn: values.acn,
                web_url: values.webUrl,
                business_name: values.businessName,
                manufacturer_id: manufacturerId,
                depot_id: values.depot_id === -1 ? null : values.depot_id,
            })
                .unwrap()
                .then((response) => {
                    // navigate to success page
                    void navigate('/v2/register-success', {
                        replace: true,
                        state: {user: response},
                    });
                })
                .catch((errorResponse: {data: Response}) => {
                    const {error: errorMessage} = errorResponse?.data || {
                        error: null,
                    };
                    setError(
                        errorMessage
                            ? errorMessage
                            : 'Oops! Something went wrong. Please verify your information and resubmit.'
                    );
                });
        },
        [selectedCountry, manufacturerId]
    );

    const toggleCompanyExpanded = useCallback(
        () => setCompanyExpanded((oldState) => !oldState),
        []
    );

    if (!permitSelfRegistration) {
        // push to login if self registration is not allowed
        void navigate('/v2/login', {
            replace: true,
        });
        return null;
    }

    if (isLoadingCountries) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }

    const hasDepot =
        customerDepots &&
        customerDepots?.depots?.length &&
        customerDepots?.setting?.is_depot_functionality_enabled &&
        customerDepots?.setting?.selectable_by_customer;

    return (
        <Formik
            validationSchema={formSchema}
            initialValues={REGISTRATION_DEFAULTS}
            enableReinitialize={true}
            onSubmit={onSubmit}>
            {({errors, touched}) => {
                const onCountrySelection = useCallback(
                    (country: string) => {
                        setSelectedCountry(
                            countries?.find(({id}) => id === country)
                        );
                        setCantFindAddress(false);
                    },
                    [countries]
                );
                return (
                    <Form>
                        <Container>
                            <Row
                                style={{
                                    fontSize: '.9em',
                                }}>
                                <LoginContentColumn>
                                    <Title style={{marginTop: 0}}>
                                        <Icon
                                            style={{marginRight: '15px'}}
                                            iconName={'Person.svg'}
                                            color="#FFF"
                                        />
                                        Create New Account
                                    </Title>
                                </LoginContentColumn>
                            </Row>
                            <Row>
                                <LoginContentColumn>
                                    <DisplayMessage
                                        errors={errors}
                                        touched={touched}
                                    />
                                    {error !== null ? (
                                        <Alert
                                            style={{
                                                marginBottom: '15px',
                                                paddingLeft: '15px',
                                                textAlign: 'left',
                                            }}
                                            variant="danger">
                                            {error}
                                        </Alert>
                                    ) : null}
                                </LoginContentColumn>
                            </Row>
                            <Row>
                                <LoginContentColumn>
                                    <InputWrapper
                                        style={{padding: '0 15px 15px'}}>
                                        {PERSONAL_INFO_FIELDS.map((field) => (
                                            <InputField
                                                key={field.name}
                                                type={field.type}
                                                label={field.label}
                                                name={field.name}
                                                placeholder={field.placeholder}
                                                required={field.required}
                                            />
                                        ))}

                                        {!countryId ? (
                                            <CountryDropdown
                                                countries={countries}
                                                selectCallback={
                                                    onCountrySelection
                                                }
                                            />
                                        ) : null}
                                        <SearchAddressField
                                            disabled={
                                                !selectedCountry && !countryId
                                            }
                                            countryId={selectedCountry?.id}
                                            addressStates={
                                                selectedCountry?.states
                                            }
                                            cantFindAddress={cantFindAddress}
                                            setCantFindAddress={
                                                setCantFindAddress
                                            }
                                        />

                                        {cantFindAddress ? (
                                            <CannotFindAddress
                                                postalCodeLength={
                                                    countryData?.postalCodeLength
                                                }
                                                isLoadingCountry={
                                                    loadingCountry ||
                                                    fetchingCountry
                                                }
                                                selectedCountry={
                                                    selectedCountry
                                                }
                                            />
                                        ) : null}
                                        {hasDepot ? (
                                            <DepotDropdown
                                                depots={customerDepots.depots}
                                                fieldLabel={
                                                    customerDepots?.setting
                                                        ?.identification
                                                }
                                            />
                                        ) : null}
                                    </InputWrapper>
                                    <Flex>
                                        <CollapseHeader
                                            onClick={toggleCompanyExpanded}>
                                            Company Details
                                            {companyExpanded ? (
                                                <Icon
                                                    key="icon-up"
                                                    width="19px"
                                                    iconName={'Chevron-up.svg'}
                                                />
                                            ) : (
                                                <Icon
                                                    key="icon-down"
                                                    width="19px"
                                                    iconName={
                                                        'Chevron-down.svg'
                                                    }
                                                />
                                            )}
                                        </CollapseHeader>
                                    </Flex>
                                    <Divider />
                                    {companyExpanded ? (
                                        <InputWrapper style={{padding: 15}}>
                                            {BUSINESS_FIELDS.map((field) => (
                                                <InputField
                                                    key={field.name}
                                                    type={field.type}
                                                    label={field.label}
                                                    name={field.name}
                                                    placeholder={
                                                        field.placeholder
                                                    }
                                                />
                                            ))}
                                        </InputWrapper>
                                    ) : null}
                                </LoginContentColumn>
                            </Row>
                            <Row>
                                <LoginContentColumn>
                                    <Row
                                        style={{
                                            marginTop: '15px',
                                        }}>
                                        <Col
                                            md={{span: 6, order: 1}}
                                            xs={{span: 12, order: 2}}>
                                            <CBCButton as={Link} to="/v2/login">
                                                Back to Login
                                            </CBCButton>
                                        </Col>
                                        <Col
                                            md={{span: 6, order: 2}}
                                            xs={{span: 12, order: 1}}>
                                            <CBCButton
                                                type="submit"
                                                disabled={isSubmitting}>
                                                {isSubmitting ? (
                                                    <Spinner
                                                        style={{
                                                            width: '25px',
                                                            height: '25px',
                                                            marginTop: '5px',
                                                        }}
                                                        animation="border"
                                                        role="status">
                                                        <span className="visually-hidden">
                                                            Submitting...
                                                        </span>
                                                    </Spinner>
                                                ) : (
                                                    <>Submit</>
                                                )}
                                            </CBCButton>
                                        </Col>
                                    </Row>
                                </LoginContentColumn>
                            </Row>
                        </Container>
                    </Form>
                );
            }}
        </Formik>
    );
};

const CollapseHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 16px;
    width: 100%;
    margin-top: 15px;
    cursor: pointer;
    font-weight: 500;
`;

const Divider = styled.div`
    width: calc(100% - 32px);
    height: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0px auto 1rem;
`;
