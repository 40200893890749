import {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {AppState} from 'store/customer/storeSetup';
import {
    selectLoading,
    selectProduct,
    selectValidationData,
    ValidationDataInterface,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {Product} from 'components/customer/Product/entity';
import {useFormikContext} from 'formik';
import {useQuickProductFormFields} from 'hooks';
import {
    Material,
    Door,
    MaterialType,
} from 'components/customer/Materials/entity';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {getDoor} from 'components/customer/Materials/store/selectors/doorSelector';
import {mapDoorBack} from 'components/customer/Materials/helper/doorHelper';
import {cloneDeep, isEqual} from 'lodash';
import {useGetQFPProductStructureQuery} from 'components/customer/Product/store/productApi';
import {
    PartialForm,
    drillingEnabled,
} from 'components/customer/QFPRedux/helpers/fieldHelpers';
import {getQFPFaceHeights} from './helpers/getQFPFaceHeights';

interface FormFieldsComponentInterface {
    index: number;
    isActive: boolean;
    product?: Product;
    validationData?: ValidationDataInterface;
    material?: Material;
    door?: Door;
    loading?: boolean;
    defaultLoaded?: boolean;
}

interface PendingValues {
    name: string;
    value: number | string | number[];
}

const FormFieldsComponent = ({
    loading,
    index,
    isActive,
    validationData,
    product,
    material,
    door,
}: FormFieldsComponentInterface) => {
    const {data: validStructure} = useGetQFPProductStructureQuery({
        cabinetType: product.type,
    });
    const {
        values,
        setFieldValue: setFormikValue,
        setFieldTouched,
        initialValues,
    } = useFormikContext<PartialForm>();
    const [pendingValues, setPendingValues] = useState<PendingValues[]>([]);

    const setFieldValueWithCheck = useCallback(
        (name: string, value: number | string | number[]) => {
            setPendingValues((oldValues) => [...oldValues, {name, value}]);
        },
        []
    );

    const manualFaceHeightUpdate = useCallback(
        (amount: number, values: PartialForm) => {
            const totalHeight = Number(values.height);
            const gap = Number(values.cabinet_drawer_gap);
            const drawerFaceHeights = values.drawer_face_height;

            const {faceHeightValues} = getQFPFaceHeights(
                amount,
                totalHeight,
                gap,
                gap,
                [],
                drawerFaceHeights
            );

            if (!isEqual(drawerFaceHeights, faceHeightValues)) {
                void setFormikValue('drawer_face_height', faceHeightValues);
            }
        },
        []
    );

    const setFieldValue = useCallback(
        (name: string, value: number | number[] | string) => {
            const data = {[name]: value};
            let update = true;

            if (name == 'cabinet_width_door_1') {
                data.width = value;
            }

            if (isEqual(values[String(name)], value)) update = false;

            if (update && name == 'drillings') {
                update = drillingEnabled(validStructure, values);
            }

            if (name == 'drawer_amount') {
                manualFaceHeightUpdate(Number(value), values);
                // Force drawer face type back to individual face (default for
                // advanced materials) when drawer face type dropdown is initially
                // displayed (drawer amount > 1)
                if (
                    initialValues.drawer_amount <= 1 &&
                    Number(value) > 1 &&
                    door.is_advanced
                ) {
                    void setFormikValue('drawer_face_type', '1');
                }
            }

            if (initialValues && Object.keys(initialValues).length <= 3) {
                setFieldValueWithCheck(name, value);
            } else {
                if (update) {
                    void setFormikValue(name, value);
                    void setFieldTouched(name, true);
                }
            }
        },
        [values]
    );

    const allValidationData = useMemo(() => {
        const allValidationData = cloneDeep(validationData);
        if (material) {
            allValidationData.cabinet_ext_colour = {
                maxHeight: material.length,
                maxWidth: material.width,
                customColour: material.is_custom_colour,
                doubleSided: material.is_double_sided,
                horGrain: material.is_grained,
            };
        }

        if (door) {
            allValidationData.cabinet_door = mapDoorBack(door);
        }

        if (values?.drawer_amount) {
            allValidationData.drawer_amount = values?.drawer_amount;
        }

        return allValidationData;
    }, [validationData, material, door, values?.drawer_amount]);

    useEffect(() => {
        if (
            values &&
            Object.keys(values).length > 3 &&
            pendingValues.length > 0
        ) {
            pendingValues.forEach(({name, value}) => {
                void setFieldValue(name, value);
                void setFieldTouched(name, true);
            });
            setPendingValues([]);
        }
    }, [values, pendingValues, setFieldValue, setFieldTouched]);

    return useQuickProductFormFields(
        isActive,
        index,
        validStructure?.validStructure,
        allValidationData,
        product,
        setFieldValue,
        loading,
        loading,
        false
    );
};

export default connect(
    (state: AppState, {index}: FormFieldsComponentInterface) => ({
        loading: selectLoading(state, index),
        product: selectProduct(state, index),
        validationData: selectValidationData(state, index),
        material: getMaterial(state, MaterialType.EXTERIOR, index),
        door: getDoor(state, MaterialType.EXTERIOR, index),
    })
)(FormFieldsComponent);
