import {Preview3DComponentProps} from 'components/customer/Preview3D/types';
import React, {lazy, Suspense} from 'react';
import {Loader} from 'shared/helpers';

const Preview3DComponent = lazy(
    () => import('components/customer/Preview3D/components/Preview3DComponent')
);

export const Preview3DComponentLazy = (props: Preview3DComponentProps) => (
    <Suspense fallback={<Loader loader={true} />}>
        <Preview3DComponent {...props} />
    </Suspense>
);
