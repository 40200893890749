import React, {useCallback, useMemo} from 'react';
import {
    FieldContainer,
    StyledDropdown,
} from 'components/customer/Auth/StyledElements';
import {Dropdown} from 'shared';
import {useFormikContext} from 'formik';
import {RegisterFormFields} from 'components/customer/Auth/Registration/helpers';
import {Depot} from 'components/manufacturer/DepotSettings/depotApi';

interface DepotDropdownProps {
    depots: Depot[];
    fieldLabel: string;
}

const DepotDropdown = ({depots, fieldLabel = 'Depot'}: DepotDropdownProps) => {
    const {setFieldValue, values} =
        useFormikContext<Partial<RegisterFormFields>>();

    const onDepotSelection = useCallback((_name: string, depot: number) => {
        void setFieldValue('depot_id', depot);
    }, []);

    const countriesOptions = useMemo(() => {
        const options: {
            id: number;
            name: string;
            value: number;
            selected: boolean;
        }[] = [];
        if (depots) {
            options.push(
                ...depots.map((depot) => ({
                    id: depot.id,
                    name: depot.depot_name,
                    value: depot.id,
                    selected: depot.is_default,
                }))
            );
        }
        return options;
    }, [depots]);

    return (
        <FieldContainer $required>
            <label style={{maxWidth: 100}}>{fieldLabel}</label>
            <StyledDropdown>
                <Dropdown
                    options={countriesOptions}
                    name="depot"
                    data-testid="depot-select"
                    value={values.depot_id}
                    selectHandler={onDepotSelection}
                />
            </StyledDropdown>
        </FieldContainer>
    );
};

export default DepotDropdown;
