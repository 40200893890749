import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    Loader,
    currentPageSet,
    loadingNewColourSet,
    loadingSet,
    materialSet,
    materialsAdd,
    materialsSet,
    selectBrandIds,
    selectCabinetType,
    selectCurrentPage,
    selectDoor,
    selectFinishIds,
    selectMaterial,
    selectMaterialType,
    totalPagesSet,
    totalRecordsSet,
    loadingMatchingEdgeSet,
    edgeSet,
    selectMenu,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import {shallowEqual} from 'react-redux';
import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import {
    useLazyGetMatchedEdgeQuery,
    useLazyListMaterialsQuery,
} from 'components/customer/Materials/store/materialApi';
import {
    mapEdgeMaterial,
    mapMaterial,
} from 'components/customer/AdvancedMaterials/helpers/mappers';
import {Door} from 'components/customer/Materials/entity';
import {useNotificationContext} from 'contexts';
import {genericMessageHandler} from 'shared/helpers';
import {Menu} from 'components/customer/AdvancedMaterials/entity/Menu';
import {useSearchText} from 'components/customer/AdvancedMaterials/helpers/useSearchText';
import {matchColor} from 'components/customer/AdvancedMaterials/helpers/matchMaterial';
import {Material} from 'components/customer/AdvancedMaterials/entity/Material';
import OGMaterial from 'components/customer/Materials/entity/Material';

export const useMaterials = (hasDoor: boolean) => {
    const dispatch = useAppDispatch();
    const {notify} = useNotificationContext();

    const {data: user} = useGetUserQuery();

    const cabinetType = useAppSelector(selectCabinetType);
    const currentPage = useAppSelector(selectCurrentPage);

    const materialType = useAppSelector(selectMaterialType, shallowEqual);
    const door = useAppSelector(selectDoor, shallowEqual);
    const selectedFinishes = useAppSelector(selectFinishIds, shallowEqual);
    const selectedBrands = useAppSelector(selectBrandIds, shallowEqual);
    const selectedMaterial = useAppSelector(
        selectMaterial,
        shallowEqual
    ) as Material<OGMaterial>;
    const selectedMenu = useAppSelector(selectMenu);

    const initialized = useRef(false);
    const doorRef = useRef(door);
    const materialRef = useRef(selectedMaterial);

    const {searchText} = useSearchText();
    const [fetchMaterialList] = useLazyListMaterialsQuery();
    const [fetchMatchingEdges] = useLazyGetMatchedEdgeQuery();

    const isDoorLocked = useMemo(() => {
        return (
            hasDoor &&
            door?.default_material_id != null &&
            door?.id != undefined
        );
    }, [door, hasDoor]);

    const fetchColor = useCallback(
        async (
            door: Door,
            finishes: string[],
            brands: string[],
            currentPage: number,
            newColour: boolean,
            searchText: string
        ) => {
            let loading = Loader.LOADING_INITIAL;
            if (initialized.current) {
                if (currentPage == 1) {
                    loading = Loader.LOADING_DATA;
                } else {
                    loading = Loader.LOADING_PAGINATION;
                }
            }

            if (newColour) {
                dispatch(loadingNewColourSet(true));
            }

            dispatch(loadingSet(loading));

            // NOTE: Before loading up the new color list when door is updated
            // Need to find if material with same name exists with new door filter
            let setColor = newColour;
            if (newColour) {
                const {
                    data: {data: materials},
                } = await fetchMaterialList(
                    {
                        cabinetType,
                        currentPage: 1,
                        pageSize: 999,
                        doorFilter:
                            hasDoor && door ? door.filter_name : undefined,
                        sort: 'name, brand_id, finish, substrate, thickness ASC',
                        keywords:
                            selectedMaterial &&
                            selectedMaterial.hasOwnProperty('name')
                                ? selectedMaterial.name.replace(/\(.*?\)/g, '')
                                : undefined,
                        manufacturerId: user.manufacturerId,
                        materialType: materialType.id,
                    },
                    true
                );

                if (materials && materials.length > 0) {
                    const matchingColor = matchColor(
                        selectedMaterial,
                        materials.map(mapMaterial)
                    );

                    if (matchingColor) {
                        dispatch(materialSet(matchingColor));
                        setColor = false;
                    }
                }
            }

            try {
                const {
                    data: {data: materials, pagination, groupCount},
                } = await fetchMaterialList(
                    {
                        cabinetType,
                        currentPage,
                        pageSize: 100,
                        doorFilter: hasDoor ? door.filter_name : undefined,
                        brands,
                        finishes,
                        sort: 'name, brand_id, finish, substrate, thickness ASC',
                        keywords: searchText,
                        manufacturerId: user.manufacturerId,
                        materialType: materialType.id,
                    },
                    true
                );

                if (materials && materials.length > 0) {
                    if (currentPage == 1) {
                        // Select new material from this list if setColor is true.
                        // This basically means door filter is updated and current
                        // selection is no longer relevant and previous request
                        // to find material with same name and new door filter failed.
                        if (setColor) {
                            dispatch(materialSet(mapMaterial(materials[0])));
                        }

                        dispatch(totalPagesSet(pagination.page_count));
                        dispatch(totalRecordsSet(groupCount));
                        dispatch(materialsSet(materials.map(mapMaterial)));
                    } else {
                        dispatch(materialsAdd(materials.map(mapMaterial)));
                    }
                } else {
                    dispatch(totalPagesSet(0));
                    dispatch(totalRecordsSet(groupCount));
                    dispatch(currentPageSet(1));
                    dispatch(materialsSet([]));
                }
            } catch (e) {
                genericMessageHandler(notify, {
                    message: 'Error loading materials',
                });
            } finally {
                dispatch(loadingSet(Loader.IDLE));
                dispatch(loadingNewColourSet(false));
                initialized.current = true;
            }
        },
        [cabinetType, hasDoor, user, selectedMaterial, materialType, notify]
    );

    const getMatchingEdge = useCallback(
        async (selectedMaterial: Material<OGMaterial>) => {
            try {
                dispatch(loadingMatchingEdgeSet(true));
                const {
                    data: {data: edges},
                } = await fetchMatchingEdges(
                    {
                        cabinetType: cabinetType,
                        doorFilter:
                            (door?.is_default_edge_type ||
                                door?.is_default_edge_type_locked) &&
                            door?.edge?.door_filter
                                ? door.edge.door_filter
                                : selectedMaterial.data.door_filter,
                        brand:
                            (door?.is_default_edge_type ||
                                door?.is_default_edge_type_locked) &&
                            door?.edge?.brand?.name
                                ? door.edge.brand.name
                                : selectedMaterial.data.brand.name,
                        finish:
                            (door?.is_default_edge_type ||
                                door?.is_default_edge_type_locked) &&
                            door?.edge?.finish
                                ? door.edge.finish
                                : selectedMaterial.data.finish,
                        keywords:
                            (door?.is_default_edge_type ||
                                door?.is_default_edge_type_locked) &&
                            door?.edge?.name
                                ? door.edge.name
                                : selectedMaterial.data.name,
                        materialType: materialType.id,
                    },
                    true
                );

                if (edges && edges.length) {
                    const edge = edges[0];

                    dispatch(edgeSet(mapEdgeMaterial(edge)));
                }
            } catch (error) {
                genericMessageHandler(notify, {
                    message: 'Error while fetching Edge',
                });
            } finally {
                dispatch(loadingMatchingEdgeSet(false));
            }
        },
        [notify, materialType, cabinetType]
    );

    useEffect(() => {
        if (typeof doorRef.current === 'undefined' || doorRef.current == null) {
            doorRef.current = door;
        }
    }, [door]);

    useEffect(() => {
        if (selectedMenu != Menu.MATERIAL) return;

        // Select new colour if door filter is different.
        let selectNewColour = false;

        if (materialType && materialType.id != selectedMaterial.type.id) {
            selectNewColour = true;
        }

        if (
            !selectNewColour &&
            door &&
            doorRef?.current?.filter_name != door.filter_name
        ) {
            doorRef.current = door;
            selectNewColour = true;
        }

        if ((hasDoor && door) || !hasDoor) {
            void fetchColor(
                door,
                selectedFinishes,
                selectedBrands,
                selectNewColour ? 1 : currentPage,
                selectNewColour,
                searchText
            );
        }
    }, [
        materialType,
        door,
        selectedBrands,
        selectedFinishes,
        currentPage,
        selectedMenu,
        searchText,
    ]);

    useEffect(() => {
        return () => {
            if (initialized.current) {
                initialized.current = false;
            }
        };
    }, []);

    useEffect(() => {
        if (
            selectedMaterial &&
            (!hasDoor ||
                (hasDoor && !isDoorLocked) ||
                (hasDoor && door?.edge?.name != undefined))
        ) {
            if (materialRef.current == null) {
                materialRef.current = selectedMaterial;
            }

            if (
                materialRef.current.id == selectedMaterial.id &&
                door?.is_default_edge_type != true &&
                door?.is_default_edge_type_locked != true
            ) {
                return;
            }

            materialRef.current = selectedMaterial;
            void getMatchingEdge(selectedMaterial);
        }
    }, [selectedMaterial, isDoorLocked, hasDoor]);
};
