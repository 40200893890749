import React from 'react';
import {getPhoneRegex} from 'shared/validation/phoneNumber';
import {webUrl} from 'shared/validation/validationRegex';
import * as Yup from 'yup';

export const PERSONAL_INFO_FIELDS = [
    {
        type: 'text',
        label: 'Name',
        name: 'fullName',
        placeholder: 'Full Name',
        required: true,
    },
    {
        type: 'text',
        label: 'Business Name',
        name: 'businessName',
        placeholder: 'Business Name',
        required: false,
    },
    {
        type: 'email',
        label: 'Email',
        name: 'email',
        placeholder: 'Email Address',
        required: true,
    },
    {
        type: 'text',
        label: 'Phone',
        name: 'phone',
        placeholder: 'Phone',
        required: true,
    },
];

export const ADDRESS_FIELDS = [
    {
        type: 'text',
        label: 'Address Line 1',
        name: 'address',
        placeholder: 'Address Line 1',
        required: true,
    },
    {
        type: 'text',
        label: 'Address Line 2',
        name: 'address2',
        placeholder: 'Address Line 2',
        required: false,
    },
    {
        type: 'text',
        label: (
            <>
                Suburb/
                <wbr />
                City
            </>
        ),
        name: 'suburb',
        placeholder: 'Suburb/City',
        required: true,
    },
    {
        type: 'text',
        label: 'Postcode',
        name: 'postcode',
        placeholder: 'Postcode',
        required: true,
        disabled: false,
        maxLength: 5,
    },
];

export const BUSINESS_FIELDS = [
    {
        type: 'text',
        label: 'ABN',
        name: 'abn',
        placeholder: 'ABN',
    },
    {
        type: 'text',
        label: 'ACN',
        name: 'acn',
        placeholder: 'ACN',
    },
    {
        type: 'text',
        label: 'Web URL',
        name: 'webUrl',
        placeholder: 'Web URL',
    },
];

export interface RegisterFormFields {
    fullName: string;
    email: string;
    phone: string;
    country: string;
    address: string;
    address2: string;
    suburb: string;
    postcode: string;
    state: string;
    addressSearch: string;
    businessName: string;
    abn: string;
    acn: string;
    webUrl: string;
    depot_id: number;
}

export const REGISTRATION_DEFAULTS: RegisterFormFields = {
    fullName: '',
    email: '',
    phone: '',
    country: '',
    address: '',
    address2: '',
    suburb: '',
    postcode: '',
    state: '',
    addressSearch: '',
    businessName: '',
    abn: '',
    acn: '',
    webUrl: '',
    depot_id: -1,
};

export const getFormSchema = (
    stateLabel: string,
    hasCountry: boolean,
    isCountrySelected: boolean,
    cantFindAddress: boolean
) =>
    Yup.object().shape({
        fullName: Yup.string().required('Please enter your full name'),
        email: Yup.string()
            .email('Please enter a valid email address')
            .required('Please enter your email'),
        phone: Yup.string()
            .required('Please enter your phone number')
            .matches(getPhoneRegex(''), 'Please enter a valid phone number'),
        webUrl: Yup.string()
            .matches(webUrl, 'Please enter a valid website address')
            .nullable()
            .notRequired(),
        abn: Yup.number()
            .typeError('Please enter a valid ABN number')
            .nullable()
            .notRequired(),
        acn: Yup.number()
            .typeError('Please enter a valid ACN number')
            .nullable()
            .notRequired(),
        ...(hasCountry
            ? {country: Yup.string().required('Please choose a country')}
            : {}),
        ...(isCountrySelected && cantFindAddress
            ? {
                  address: Yup.string().required('Please enter your address'),
                  suburb: Yup.string().required(
                      'Please enter your suburb/city'
                  ),
                  postcode: Yup.string().required('Please enter your postcode'),
                  state: Yup.string().required(`Please choose a ${stateLabel}`),
              }
            : {}),
        ...(isCountrySelected && !cantFindAddress
            ? {
                  addressSearch: Yup.string().required(
                      'Please enter your address'
                  ),
              }
            : {}),
    });
