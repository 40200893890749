import React from 'react';
import {Col, ColProps} from 'react-bootstrap';
import {useCustomMediaQuery} from 'shared/helpers/DeviceSize';
import {useTheme} from 'styled-components';

const ratio = window.devicePixelRatio;

export const useLoginLayoutHelper = () => {
    const centeredLoginThreshold = useCustomMediaQuery({maxWidth: 1024});
    const fullWidthCardThreshold = useCustomMediaQuery({maxWidth: 480});

    return {
        centeredLoginThreshold,
        fullWidthCardThreshold,
    };
};

export const LoginContentColumn: React.FC<
    React.AllHTMLAttributes<HTMLDivElement> & ColProps
> = ({children, ...props}) => {
    const theme = useTheme();
    const {centeredLoginThreshold, fullBackground} = theme.device;

    return (
        <Col
            md={
                ratio >= 2.5 || fullBackground || centeredLoginThreshold
                    ? 12
                    : {span: 6, offset: 3}
            }
            {...props}>
            {children}
        </Col>
    );
};
