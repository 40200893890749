export enum Shape {
    SQR = 'rectangle',
    ANG = 'l_shape',
    USHAPE = 'u_shape',
}

export const getShape = (shape: string) => {
    switch (shape) {
        case 'rectangle':
        case 'Straight':
            return Shape.SQR;
        case 'L Shape':
        case 'l_shape':
            return Shape.ANG;
        case 'U Shape':
        case 'u_shape':
            return Shape.USHAPE;
    }
};
