import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {Material} from 'components/customer/AdvancedMaterials/entity/Material';
import {BenchtopBrand} from 'components/customer/BTM/entity/BenchtopBrand';
import {Brand} from 'components/customer/AdvancedMaterials/entity/Brand';
import {BenchtopFinish} from 'components/customer/BTM/entity/BenchtopFinish';
import {Finish} from 'components/customer/AdvancedMaterials/entity/Finish';
import ProductMaterial from 'components/customer/Materials/entity/Material';
import {Group} from 'components/customer/AdvancedMaterials/store/materialSlice';
import { MaterialEdge } from 'components/customer/Materials/entity';

export const mapMaterial = <T extends ProductMaterial>(
    material: T
): Material<T> => {
    return {
        id: material.id,
        name: material.name,
        finish: {
            name: material.finish,
        },
        image: material?.image,
        brand: {
            id: String(material.brand.id),
            name: material.brand.name,
        },
        thickness: material.thickness,
        data: material,
        substrate: material?.substrate,
        type: {
            id: String(material.type.id),
            name: material.type.name,
            isVinyl: material.type.is_vinyl,
        },
    };
};

export const mapEdgeMaterial = <T extends MaterialEdge>(
    edge: T
): Material<T> => {
    return {
        id: edge.id,
        name: edge.name,
        finish: {
            name: edge.finish,
        },
        image: edge?.image,
        brand: {
            id: String(edge.brand.id),
            name: edge.brand.name,
        },
        thickness: edge.thickness,
        data: edge,
        type: {
            id: String(edge.material_type.id),
            name: edge.material_type.name,
            isVinyl: edge.material_type.is_vinyl,
        }
    }
};

export const mapBenchtopMaterial = (
    material: BenchtopMaterial
): Material<BenchtopMaterial> => {
    return {
        id: material.id,
        name: material.name,
        finish: {
            id: String(material.finish.id),
            name: material.finish.name,
        },
        substrate: material?.substrate?.name,
        image: material?.image?.name,
        type: {
            id: String(material.type.id),
            name: material.type.name,
        },
        brand: {
            id: String(material.brand.id),
            name: material.brand.name,
        },
        thickness: material.thickness,
        data: material,
    };
};

export const groupMaterials = (
    materials: Material[],
    groups: Group<Material>[] = []
): Group<Material>[] => {
    const groupedMaterials = materials.reduce(
        (acc: Group<Material>[], material: Material) => {
            const key = `${material.brand.id}-${material.name}`;
            const group = acc.find((group) => group.key === key);

            if (group) {
                group.materials.push(material);
            } else {
                const newGroup = {
                    key,
                    materials: [material],
                };
                acc.push(newGroup);
            }

            return acc;
        },
        groups
    );

    const names = groupedMaterials.map((group) => group?.materials[0].name);
    const nameCounts = names.reduce(function (
        acc: Record<string, number>,
        val
    ) {
        if (acc[val]) {
            acc[val]++;
        } else {
            acc[val] = 1;
        }
        return acc;
    },
    {});

    const groupedWithBrands = groupedMaterials.map((group) => {
        const name = group.materials[0].name;

        if (nameCounts[name] > 1) {
            group.materials[0].name = `${name} (${group.materials[0].brand.name})`;

            return group;
        }

        return group;
    });

    return groupedWithBrands;
};

export const mapBenchtopBrand = (brand: BenchtopBrand): Brand => {
    return {
        id: String(brand.id),
        name: brand.name,
        selected: false,
    };
};

export const mapBenchtopFinish = (finish: BenchtopFinish): Finish => {
    return {
        id: String(finish.id),
        name: finish.name,
        selected: false,
    };
};
